import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DocumentLayout from "../layouts/Document";
import { Indent } from "../components/DocumentParts";
import imagePN from "../images/pn.png";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <DocumentLayout title="Deleting Your Peek a Phone Account">
        {children}
    </DocumentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Deleting Your Peek a Phone Account`}</h1>
    <p>{`When you delete your account, any progress you have made in the game, including past purchases will be permanently removed.`}</p>
    <p>{`For security reasons, we can't delete an account for you. You'll need to be able to log into your account to request deletion.`}</p>
    <p><strong parentName="p">{`To request the permanent deletion of your account from the app:`}</strong></p>
    <ol>
      <li parentName="ol">{`Open to the Settings menu.`}</li>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Delete account`}</strong>{`.`}</li>
      <li parentName="ol">{`Follow the instructions and approve the deletion.`}</li>
    </ol>
    <p>{`After 30 days of your account deletion request, your account and all your information will be permanently deleted, and you won't be able to retrieve your information. During those 30 days the content remains subject to FaintLines's `}<Link to="/termsofuse" mdxType="Link">{`Terms of Use`}</Link>{` and `}<Link to="/privacy" mdxType="Link">{`Privacy Policy`}</Link>{`.`}</p>
    <p>{`It may take up to 90 days to complete the deletion process after it begins. Copies of your content may remain after the 90 days in backup storage that we use to recover in the event of a disaster, software error, or other data loss event. We may also keep your information for things like legal issues, terms violations, or harm prevention efforts. Learn more about this in our `}<Link to="/privacy" mdxType="Link">{`Privacy Policy`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      